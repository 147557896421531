.Home-page-show-mor-button {
    display: inline-flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    background: var(--orange, #03001e);
}

.Home-page-show-mor-button-text {
    color: var(--white, #FFF);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 116.667% */
    text-transform: uppercase;
    margin-bottom: 0;
}

.Home-page-show-mor-button-Arrow {
    width: 17.5px;
    height: 11.046px;
    fill: var(--white, #FFF);
}

.Home-page-search-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

#main-logo {
    width: auto;
    height: 120px;
    flex-shrink: 0;
}