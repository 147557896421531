.login-form {
  margin: auto;
  width: 486px;
  height: 377px;
  gap: 48px;
  top: 352px;
  left: 477px;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .login-form {
    width: 100%;
  }
  .input-from-login-page {
    width: calc(100% - 40px)!important;
    left: 20px;
  }
}

#login-text {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  margin-bottom: 48px;
  text-align: center;

}

.forget-password-link {
  color: #000;
  margin: none;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;

}

.forgot-password {
  color: #000;
  margin: none;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;

}

.button-container {
  display: flex;
  justify-content: center;

}


.input-from-login-page {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  margin-top: 12px;
  margin-bottom: 12px;
  height: 60px;
  width: 486px;
  padding: 18px 24px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  background: #F2F2F2;
  position: relative;
  justify-content: center;

}


#input-from-login-uname:focus {
  outline-color: #F2F2F2;
}

#input-from-login-uname {
  border-radius: 15px;
  border-color: #F2F2F2;
}


#input-from-login-pass:focus {
  outline-color: #F2F2F2;
}

#input-from-login-pass {
  border-radius: 15px;
  border-color: #F2F2F2;
}


.error-text-login-page {
  color: var(--red, #FF6174);
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;

}

/* forgot password style */
#forgot-text {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  text-align: center;

}

#enter-email-text {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: center;

}

#forgot-text-bar {
  margin-bottom: 48px;

}

#icon-back-log {
  color: var(--dark-grey, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
}

.input-from-forgot-page {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  margin-top: 12px;
  margin-bottom: 12px;
  height: 60px;
  width: 486px;
  padding: 18px 24px;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  background: #F2F2F2;
  position: relative;
  justify-content: center;

}

#input-from-forgot-uname:focus {
  outline-color: #F2F2F2;
}

#input-from-forgot-uname {
  border-radius: 15px;
  border-color: #F2F2F2;
}

.send-button-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;

}

.finish-forgot-password-text {
  color: var(--Gray-1, #333);
  font-family: Noto Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

/* finish forgot bar */

.finish-forgot-bar {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 39px;
}

.check-email-text-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.check-email {
  color: var(--Gray-1, #333);
  font-family: Noto Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.send-password-reset {
  color: var(--orange, #03001e);

  /* body */
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 131.25% */
}