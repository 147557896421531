.Contact-page-full-div {
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

.Contact-page-title {
    color: var(--Gray-1, #333);
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.Contact-page-tou-agent-box {
    display: flex;
    width: 1200px;
    align-items: flex-start;
    gap: 24px;
}

.Contact-page-agents-full-div {
    display: inline-flex;
    flex-direction: column;
    gap: 48px;
}

.Contact-page-page-lier-agent-box {
    display: flex;
    padding: 32px;
    width: 588px;
    height: 325px;
}

@media only screen and (max-width: 1230px) {
    .Contact-page-agents-full-div {
        width: 100%;
    }
    .Contact-page-tou-agent-box {
        width: 100%;
        flex-direction: column;
    }
    .Contact-page-agent-box-full-div {
        width: 100%;
    }
    .Contact-page-page-agent-box-A {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .Contact-page-agent-Mail-icon-text {
        font-size: 14px;
        width: 100%;
    }
    .Contact-page-agent-info-text-mail-div {
        flex-direction: column;
    }
    .Contact-page-agent-box-full-div {
        height: 410px;
    }
    .Contact-page-agent-text-info {
        gap: 15px;
    }
}