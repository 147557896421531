.ts-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
}

.ts-btn:hover {
  opacity: 80%;
}

.ts-btn:disabled {
  opacity: 100% !important;
  background: #B0AFAF;
  ;
  color: #fff;
}

.ts-btn-large {
  min-width: 264px;
  padding: 18px 24px;
  border-radius: 15px;

  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
}

.ts-btn-middle,
.ts-btn-small {
  min-width: 158px;
  padding: 12px 32px;
  border-radius: 10px;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

/* Добавил неймы стилей на будущее */
.ts-btn-rounded-large,
.ts-btn-rounded-medium,
.ts-btn-rounded-small {
  min-width: 264px;
  border-radius: 30px;
  padding: 18px 32px;

  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
}

.ts-btn-rounded-totalTesT {
  border-radius: 30px;
  padding: 18px 32px;

  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
}

.ts-btn-orange {
  background: #03001e;
  color: #fff;
}

.ts-btn-white {
  background: #fff;
  color: #03001e;
}

.ts-btn-grey {
  background: #B0AFAF;
  ;
  color: #fff;
}

.ts-btn-currency {
  position: relative;

  max-width: 52px;
  gap: 18px;
  padding: 3px 8px;
  border-radius: 50px;

  font-size: 12px;
  font-weight: 700;

  color: #fff;
  background: #B0AFAF;
  display: flex;
  height: 26px;
}

.ts-btn-round-selector {
  position: absolute;
  top: 0;

  width: 24px;
  height: 24px;
  border-radius: 50px;

  background: #03001e;

  left: 0px;
  transition: left 0.2s ease-out;
}

.ts-btn-round-selector-usd {
  width: 24px;
  left: 0;
}

.ts-btn-round-selector-middle {
  width: 52px;
}

.ts-btn-round-selector-gel {
  width: 24px;
  left: 24px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #03001e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.currency-button-full-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

}

.currency-button-tite-text {
  color: var(--dark-grey, #333);
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}