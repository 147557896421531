.Checkmark-full-div {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 4;
  transform: translate(-50%, -50%);
}

.Checkmark-div{
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-50%, -50%);
}