.commentar-autor-name{
    font-weight: bold;
    margin-top: 10px;
}


.testClas {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.open-home-page-agent-iamge-div {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.open-home-page-agent-iamge{
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
}

.commentar-agents-dtm{
    font-weight: bold;
    margin-top: 15px;
    font-size: 12px;
    
}
.comment-agent-image{
  width: 50px;
  height: 50px;
  border-radius: 50%;  
  object-fit: cover; 
}
.agent{
  display: flex;
  flex-direction: row;
}
.comment-page-input {
    margin: 6px 0 0px 0;
    padding: 5px;
    display: flex;
    border: 1px solid lightgray;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    background-color: #f2f2f2;  
    margin: 0;
    width: 600px;
  }
  .comment-span-class{
    margin-top: 10px;
    padding: 2px;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 100%;
  }
  .comment-page-input-full-div {
    gap: 10px;
    display: flex;
    width: 100%;
    position: left;
  }

  .comments-info-div {
    max-width: 100%; 
    width: 100%; 
  }
.comment-page-input-button{
    padding: 9px 18px;
    background: #03001e;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}


.coments-info-div {
    margin-top: 5px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    width: 1100px;
    cursor: pointer;
    border-radius: 5px;
  }

  .coments-info-div:hover {
    background-color: #f2f2f2;
  }

  .testClass, .agent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap; 
  }
  .coments-info-div>span {
    margin: 0 5px;
  }

  .coments-info-full-div{
    flex-direction: column;
    gap: 5px;
  }