.main-mobile-menu-box {
    padding: 32px 24px;
    background: #03001e;
    position: absolute;
    top: 70px;
    right: 20px;
    z-index: 5;
}

.x-position {
    position: absolute;
    right: 32px;
    top: 40px;
}

.contents-box {
    margin-top: 40px;
    color: #fff;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    flex-direction: column;
}

.contents-box button {
    color: #fff!important;
}

.contents-box span {
    color: #fff;
}

.contents-box a {
    color: #fff;
}

.background {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}