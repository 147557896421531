.header_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.header_container span:not(.tooltip) {
    font-size: 32px;
}

.header_container small {
    color: #B0AFAF;
    font-size: 14px;
}

.hover-notification {
    position: absolute;
    width: 70px;
    height: 70px;
    margin-left: -60px;
    margin-top: -10px;
    background-color: #03001e;
    border-radius: 100%;
    padding: 10px 10px;
}

.header-title {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.open-Home-page-header-agent-info-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    gap: 30px;
}

.open-Home-page-header-agent-button-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    gap: 8px;
    position: relative; 
}

.open-Home-page-header-agent-btn-div-text-div {
    margin-top: 10px;
}

.open-Home-page-header-agent-shear-btn {
    background-color: #03001e;
    border-radius: 100%;
    padding: 10px 13px;
}

.tooltip {
    visibility: hidden;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    bottom: 70px; 
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    font-size: small;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.open-Home-page-header-agent-shear-btn:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0); 
}

.open-Home-page-header-agent-shear-btn-icon {
    max-width: 30px;
    max-height: 30px;
    cursor: pointer;
    border-radius: 50%; 
    overflow: hidden; 
}

@media only screen and (max-width: 1230px) {
    .open-Home-page-header-agent-info-div {
        flex-wrap: wrap;
        max-width: calc(100% - 218px);
    }

    .open-Home-page-header-agent-button-div {
        flex-wrap: wrap;
    }
    .open-Home-page-header-agent-shear-btn {
        padding: 10px 10px;
    }
    .tooltip{
        bottom: 60px;
        max-width: 400px;
    }
}

@media (max-width: 800px) {
    .tooltip {
        width: 100px;
        font-size: 0.7rem;
        padding: 3px 6px;
    }
    .open-Home-page-header-agent-shear-btn-icon {
        max-width: 25px;
        max-height: 25px;
    }
}

@media only screen and (max-width: 450px) {
    .open-Home-page-header-agent-btn-div-text-div {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 400px) {
    .header_container span {
        font-size: 22px;
    }

    .open-Home-page-header-agent-info-div {
        max-width: calc(100% - 190px);
    }
}
