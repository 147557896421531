.post-property-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.main-container {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Post-property-page-div {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    overflow-x: auto;
}

.Post-property-page-div > button {
    white-space: nowrap;
}

@media only screen and (max-width: 1230px) {
    .main-container {
        width: 100%;
        padding: 0 5px;
    }
}