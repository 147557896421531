.image-container {
    width: 1200px;
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 26px;
}


.image-container button {
    cursor: pointer;
    font-size: 18px;
    color: #000;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    background: #F2F2F2;
    position: absolute;
    bottom: 15px;
    right: 25px;
    z-index: 2;
}

.main-picture {
    grid-column: span 1; /* Takes up one column */
    background-color: lightblue;
    height: 423px;
    width: 555px;
    cursor: pointer;
}

.small-picture-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 26px;
}

.small-picture {
    width: 285px;
    height: 205px;
    cursor: pointer;
}

.image-modal-container {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.close-button {
    position: absolute;
    right: 15px;
    height: 40px;
    top: 20px;
    background: #F2F2F2;
    padding: 10px;
    font-size: 22px;
    border-radius: 15px;
    font-weight: bold;
    bottom: 20px;
    z-index: 101;
    display: flex;
    align-items: center;
    gap: 5px;
}

.left-button-box {
    display: block;
    background : none;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 80px;
    cursor: pointer;
}

.right-button-box {
    display: block;
    background: none;
    position: absolute;
    top: 60px;
    right: 0;
    height: calc(100% - 60px);
    width: 80px;
    cursor: pointer;
}

.left-button {
    position: absolute;
    padding: 5px 12px;
    width: 65px;
    height: 65px;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    background: #F2F2F2;
    font-size: 22px;
    border-radius: 50%;
    font-weight: bold;
    z-index: 101;
}

.right-button {
    position: absolute;
    top: 50%;
    width: 65px;
    height: 65px;
    transform: translateY(-50%);
    right: 15px;
    z-index: 101;
    background: #F2F2F2;
    font-size: 22px;
    border-radius: 50%;
    font-weight: bold;
}

.modal-image {
    min-height: 400px;
    max-height: 700px;
}

.custom-icon {
    font-size: 1.3em;
}

@media only screen and (max-width: 1230px) {
    .image-container {
        width: 100%;
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .main-picture {
        width: 300px;
        height: 229px;
    }
    .small-picture {
        width: 153px;
        height: 110px;
    }
    .modal-image {
        min-height: 130px;
        max-height: 227px;
    }
}