.caroucel-activ-image-style {
    max-width: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
}

.caroucel-picture {
    width: 384px;
}

.caroucel-current-image {
    border-radius: 30px 30px 0px 0px;
    max-height: 286px;
    min-height: 286px;
    min-width: 384px;
}

@media only screen and (max-width: 400px) {
    .caroucel-current-image {
        min-width: 100%!important;
        max-width: 100%!important;
    }

    .caroucel-picture {
        width: 100%;
    }
}