* {
  font-family: 'Noto Sans', sans-serif !important;
  /* font-family: 'Noto Serif Georgian', serif; */
}

::-webkit-input-placeholder {
  color: #B0AFAF !important;
}

:-moz-placeholder {
  color: #B0AFAF !important;
}

::-moz-placeholder {
  color: #B0AFAF !important;
}

button {
  border: none;
  background: none;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;

  text-transform: uppercase;
  color: #333333;

  margin-bottom: 48px;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.App {
  text-align: left;
}

.App-logo {
  height: 45vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #b2c0de;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.orange-background {
  background: #03001e;
}

.white-font {
  color: #fff;
}

.black-font {
  color: #333333;
}

.orange-font {
  color: #03001e;
}

.grey-font {
  color: #B0AFAF;
}

span.carousel-control-next-icon, span.carousel-control-prev-icon {
  background-color: #03001e;
  border-radius: 100%;
}

ul.dropdown-menu {
  --bs-dropdown-min-width: 0rem;
  transform: translate3d(6px, 28px, 0px) !important;
  border: none !important;
}

.ts-locale-item {
  transition: 0.2s;
}

.ts-locale-item:hover {
  transform: scale(1.2);
}

.ts-base-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 1200px;
}