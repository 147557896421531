.map-wrap {
    position: relative;
    width: 100%;
    height: 100%; /* calculate height of the screen minus the heading */
}
  
.map {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
}
.maplibregl-ctrl-top-right {
    position: absolute;
    right: 50%;
    transform: translateX(+50%);
}

.marker {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03001e;
    font-size: 14px;
    color: #fff;
    border-radius: 8px;
    width: 43px;
    height: 42px;
    transition: width 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
}

.marker b {
    display: none;
    white-space: nowrap; /* Add this */
    position: absolute; /* Add this */
    left: 100%; /* Add this */
    transition: left 0.2s ease-in-out; /* Add this */
}

.marker img {
    position: absolute;
    left: 5px;
}

.marker:hover {
    width: 140px;
}

.marker:hover b {
    display: block;
    left: 40px;
}

.custom-popup {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }

  .popup-header {
    font-size: 20px;
    margin-top: 7px;
  }

  
  
  .popup-content {
    text-align: center;
  }

  @media only screen and (max-width: 1000px) {
    .map-wrap {
        width: calc(100% - 40px);
        left: 40px;

    }   
  }

  @media only screen and (max-width: 500px) {
    .map-wrap {
        width: calc(100% - 12px);
        left: 12px;
        height: 300px;
    }
  }

