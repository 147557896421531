.ts-btn-fast-filters {
    width: 384px;
    height: 129px;
    padding: 24px 48px;
    border-radius: 20px;
}

.ts-property-card {
    max-width: 384px;
    padding: 0px;
    gap: 20px;
    position: relative;
    z-index: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.ts-property-card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
    position: relative;
    z-index: auto;
}

.ts-property-card-info-head-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    min-width: 320px;
}

.ts-property-card-info-head-price-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 3;
    gap: 20px;
}

.ts-property-card-info-head-info-text {
    color: var(--dark-grey, #333);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 116.667% */
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
}

.ts-property-card-info-secondary-items-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    padding: 0px;
    gap: 20px;
    min-width: 320px;
}

.ts-property-card-info-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 30px 30px 30px;
    gap: 10px;

}

.ts-property-card-info-primary-items {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
}

.ts-property-card-info-secondary-items {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.ts-fast-filters-section {
    flex-direction: row;
    width: 100%;
}

.ts-properties-section {
    justify-content: flex-start;
}

@media screen and (max-width: 1220px) {
    .ts-fast-filters-section {
        flex-direction: column;
    }

    .ts-btn-fast-filters {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
    }

    .ts-properties-section {
        justify-content: center;
    }
}

@media only screen and (max-width: 400px) {
    .ts-base-container {
        max-width: 100% !important;
    }

    .ts-properties-section {
        max-width: 100%;
    }
}


.property-card-image-carusel {
    max-width: 100px;
    max-height: 100px;
    width: auto;
    height: auto;
}

.ts-property-card-open-apartment-image-button {
    float: left;
    height: 100%;
    width: 70%;
    z-index: 2;
    background-color: transparent;
    /* background-color: red; */
    position: absolute;
    top: 0;
    outline: none;
    border: none;
    margin-left: 15%;

}

.ts-property-card-open-apartment-main-button {
    float: left;
    height: 50%;
    width: 100%;
    z-index: 2;
    background-color: transparent;
    position: absolute;
    top: 0;
    outline: none;
    border: none;
    margin-top: 60%;

}

.Apartment-box-agent-three-point-button {

    height: 15%;
    width: 15%;
    z-index: 3;
    background-color: transparent;
    /* background-color: blue; */
    position: absolute;
    top: 0;
    outline: none;
    border: none;
    right: 0;
}

.Apartment-box-agent-three-point-button-icon {
    width: 19.662px;
    height: 4.668px;
    flex-shrink: 0;
}


.Apartment-box-from-agent-change-apartment {
    width: auto;
    height: auto;
    position: absolute;
    border-radius: 20px;
    background: var(--light-grey, #F2F2F2);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 30px;
    display: none;
    z-index: 3;
    right: 0;

}

.Apartment-box-from-agent-change-apartment-button-div {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: stretch;
}

.fast-sherch-buttons{
    gap: 15px;
}

.total-test {
    position: relative;
    z-index: auto;
}

@media only screen and (max-width: 400px) {
    .total-test {
        width: 100%;
    }
    .ts-property-card {
        width: calc(100% - 10px);
    }
}