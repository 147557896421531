.about-us-page-main {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.toggle-style {
    position: absolute;
    z-index: 5;
    left: 100px;
    background: white;
    padding: 10px;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid black;
}

.toggle-x {
    position: absolute;
    z-index: 6;
    left: calc(50% + 50px);
    margin-top: 10px;
    cursor: pointer;
}

.main-search-container {
    width: calc(50% + 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-map-container {
    width: calc(100% - 25px);
}

.main-map-container .map {
    width: 100%;
    height: 900px;
}

.main-search-container .search-bar-input-full-div {
    gap: 8px;
}

.main-search-container .search-bar-container {
    gap: 8px
}

.modal-style {
    padding: 20px;
    padding-right: 40px;
    position: absolute;
    z-index: 5;
    border: 2px solid black;
    border-radius: 10px;
    background: white;
}

.properties_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
}

/*.main-search-container .search-bar-row {
    flex-direction: column;
}*/

@media only screen and (max-width: 1600px) {
    .properties_container {
        flex-direction: column;
    }

    .main-map-container .map {
        width: 100%;

    }

    .main-search-container {
        width: 860px;
        position: absolute;
        left: 20px;
    }

    .main-search-container .properties_container {
        flex-direction: row !important;
    }

    .toggle-x {
        left: 850px;
    }
}

@media only screen and (max-width: 1500px) {
    .properties_container {
        flex-direction: row;
        margin-bottom: 20px;
    }

    .about-us-page-main {
        flex-direction: column;
        align-items: center;
    }

    .main-map-container .map {
        height: 600px;
    }

    .main-map-container .map-wrap {
        width: calc(100% - 30px);
        left: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .main-search-container {
        width: calc(100% - 40px);
    }
}

@media only screen and (max-width: 850px) {
    .main-map-container {
        width: calc(100% - 30px);
        display: flex;
        justify-content: center;
    }

    .properties_container {
        flex-direction: column;
    }

    .main-map-container .map {
        height: 500px;
    }
}

@media only screen and (max-width: 500px) {
    .main-map-container .map {
        height: 400px;
    }
}