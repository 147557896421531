.footer-box {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.footer-box h1 {
   color: #333333;
   font-size: 32px;
   font-weight: 400;
}

@media only screen and (max-width: 500px) {
    .footer-box {
        width: 100%;
        padding: 0 5px;
    }
}